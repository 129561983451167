
<template>
  <v-container fluid>
    <v-row wrap row>
      <v-col cols="12" sm="5" md="3" class="pt-4">
        <h4 class="display-1">{{ $t('settings.current_culture') }}</h4>
      </v-col>

      <v-col cols="12" md="9" class="pl-0">
        <v-row no-gutters>
          <v-col cols="12" md="10" class="pa-0 text-right">
            <v-btn outlined large
              color="primary"
              @click="showModal = !showModal"
            >
              {{ $t('input.generic_link_btn') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" class="pa-0 text-right pt-5 pt-md-0">
            <v-btn large
              color="primary"
              class="ml-2"
              to="/current-culture-assessments/create"
            >
              <v-icon left>fa-plus-circle</v-icon>
              {{ $t(`currentCulture.add`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-3">
        <v-card>
          <v-layout row wrap class="ml-3">
            <v-col cols="12" sm="3">
              <v-select
                :label="$t('employees.filter_by')"
                :items="filters"
                clearable
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-select>
            </v-col>
          </v-layout>

          <x-data-table
            :headers="headers"
            :parent-fetch-data="getCurrentCultureAssessments"
            :options="options"
            no-data="currentCulture.no_data"
          >
            <template v-slot:structure="prop">
              <td class="pl-6">
                <v-tooltip bottom color="blue" v-if="prop.item.displayName">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ prop.item.name }}</span>
                  </template>
                  <span>{{ prop.item.displayName }}</span>
                </v-tooltip>
                <span v-else>{{ prop.item.name }}</span>
              </td>

              <td class="text-center">
                <v-tooltip top color="blue" :disabled="prop.item.status !== 'creating'">
                  <template v-slot:activator="{ on }">
                    <v-chip outlined label
                      v-on="on"
                      :color="getColor(prop.item.status)"
                    >
                      {{ $t(`currentCulture.${prop.item.status}`) }}
                    </v-chip>
                  </template>
                  <span>{{ $t('input.may_take_while') }}</span>
                </v-tooltip>
              </td>

              <td class="text-center">
                {{ prop.item.populationCount }}
              </td>

              <td class="text-center">
                {{ formatDate(prop.item.releaseAt) }} {{$t('requiredCulture.to_the')}} {{ formatDate(prop.item.deadlineAt)}}
              </td>

              <td
                :key="prop.item.status"
                :class="{'text-center': prop.item.status === 'creating'}"
              >
                <template v-if="prop.item.status !== 'creating'">
                  <!-------------- Show -------------->
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :to="`/current-culture-assessments/${prop.item._id}/show`" text icon>
                        <v-icon small>visibility</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('input.view_details') }}</span>
                  </v-tooltip>

                  <!-------------- Associate -------------->
                  <v-tooltip :disabled="prop.item.status !== 'completed'" bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on" icon
                        @click="openAssociateModal(prop.item)"
                        :disabled="prop.item.status !== 'completed'"
                      >
                        <v-icon small v-if="prop.item.requiredCultureId !== null && prop.item.requiredCultureId !== undefined" color="green">mdi-arrange-bring-to-front</v-icon>
                        <v-icon small v-else>mdi-arrange-send-to-back</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('currentCulture.associate_required_culture') }}</span>
                  </v-tooltip>

                  <!-------------- Edit -------------->
                  <v-tooltip :disabled="notEditable(prop.item.status)" bottom color="green">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="['pending', 'in_progress'].includes(prop.item.status)"
                        :to="`/current-culture-assessments/${prop.item._id}/edit`"
                        v-on="on" icon
                        :disabled="notEditable(prop.item.status)"
                      >
                        <v-icon small>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('input.edit') }}</span>
                  </v-tooltip>

                  <!-------------- Close -------------->
                  <v-tooltip :disabled="notEditable(prop.item.status)" bottom color="green">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="prop.item.status === 'in_progress'"
                        v-on="on" icon
                        @click="closingPoll = true; itemToClose = prop.item._id"
                        :disabled="notEditable(prop.item.status)"
                      >
                        <v-icon small>mdi-lock</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('input.close') }}</span>
                  </v-tooltip>

                  <!-------------- Reports -------------->
                  <v-tooltip :disabled="prop.item.status !== 'completed'" bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="prop.item.status === 'completed'"
                        :to="`/current-culture-assessments/reports/${prop.item._id}`"
                        v-on="on" icon
                        :disabled="prop.item.status !== 'completed'"
                      >
                        <v-icon size="17">mdi-file-chart</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('input.view_pdf') }}</span>
                  </v-tooltip>

                  <!-------------- PBI -------------->
                  <v-tooltip :disabled="prop.item.status !== 'completed'" bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn icon
                        v-if="prop.item.status === 'completed'"
                        v-show="hasPbiAccess"
                        v-on="on"
                        :disabled="prop.item.status !== 'completed'"
                        :to="`/current-culture-assessments/power-bi/${prop.item._id}`"
                      >
                        <v-icon small>mdi-chart-bar-stacked</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('input.view_pbi') }}</span>
                  </v-tooltip>
                </template>

                <!-------------- Creating / Editing (reload) -------------->
                <v-tooltip v-else bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon
                      class="mx-auto"
                      @click="reloadList"
                    >
                      <v-icon small>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.refresh') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showModal" width="650px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('input.copy_invitation_url_title') }}</span>
        </v-card-title>
          <v-card-subtitle>
          <p class="mt-4 mb-0 text-justify body-2">
            {{ $t('input.copy_invitation_url') }}
          </p>
        </v-card-subtitle>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="11">
              <v-text-field
                :label="$t('input.invitation_url')"
                :readonly="true"
                outlined
                v-model="tokenUrl"
                id="tokenUrl"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="copyUrl" class="mt-2">
                    <v-icon>file_copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('requiredCulture.link') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn block large @click="closeModal">{{ $t('input.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="associateRequiredCulture" width="760px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">{{ $t('currentCulture.associate_required_culture_title') }}</span>
        </v-card-title>
        <v-card-subtitle>
          <p class="mb-0 pt-5">{{ $t('currentCulture.associate_required_culture_desc') }}</p>
        </v-card-subtitle>
        <v-card-text>
          <v-row justify="center" class="mt-10">
            <v-col>
              <v-overflow-btn
                v-model="selectedRequiredCulture"
                class="my-2"
                :items="requiredCultures"
                :label="$t('currentCulture.associate_required_culture_select')"
                item-value="value"
                :clearable="true"
              >
                <template v-slot:item="prop">
                  <span>
                    {{ prop.item.text }}
                  </span>
                </template>
              </v-overflow-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0 pa-2">
            <v-col cols="6" cla>
              <v-btn block large @click="associateRequiredCulture = false; selectedRequiredCulture = ''">
                {{ $t('input.close') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block large
                color="primary"
                :disabled="!selectedRequiredCulture"
                @click="saveAssociate"
              >
                {{ $t('input.associate') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <x-confirmation-modal
      :show.sync="closingPoll"
      :reversible="true"
      :title="$t('input.poll_delete_title')"
      :action="closePoll"
      :btn-save="$t('input.close_poll')"
      @close="closingPoll = false"
    >
      <template v-slot:question>{{ $t('input.master_references_delete_poll') }}</template>
    </x-confirmation-modal>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import CustomersService from '../../services/customers'
import CurrentCultureAssessmentService from '../../services/current-culture-assessment'
import RequiredCultureService from '../../services/required-culture'

export default {
  data () {
    return {
      headers: [
        { text: 'currentCulture.name', alignment: 'left' },
        { text: 'currentCulture.status', width: 140 },
        { text: 'currentCulture.participants', width: 140 },
        { text: 'currentCulture.period', width: 220 },
        { text: 'currentCulture.action', width: 177 }
      ],
      hasPbiAccess: true,
      current_cultures: [],
      modalDis: {
        open: false,
        item: {}
      },
      modalEna: {
        open: false,
        item: {}
      },
      showModal: false,
      tokenUrl: '',
      filters: [
        { text: this.$t(`page_all`), value: null },
        { text: this.$t(`pending`), value: 'pending' },
        { text: this.$t(`in_progress`), value: 'in_progress' },
        { text: this.$t(`completed`), value: 'completed' }
      ],
      options: {
        filter: null,
        search: null
      },
      search: null,
      associateRequiredCulture: false,
      closingPoll: false,
      itemToAssociate: null,
      itemToClose: null,
      selectedRequiredCulture: '',
      requiredCultures: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.filters = [
          { text: this.$t(`page_all`), value: null },
          { text: this.$t(`pending`), value: 'pending' },
          { text: this.$t(`in_progress`), value: 'in_progress' },
          { text: this.$t(`completed`), value: 'completed' }
        ]
      }
    }
  },
  methods: {
    openAssociateModal (item) {
      this.associateRequiredCulture = true
      this.itemToAssociate = item._id
      this.getRequiredCultures()
      this.selectedRequiredCulture = item.requiredCultureId
    },
    saveAssociate () {
      return CurrentCultureAssessmentService.associate(this.itemToAssociate, this.selectedRequiredCulture).then(res => {
        this.associateRequiredCulture = false
        this.itemToAssociate = null
        this.selectedRequiredCulture = ''
        this.search = this.search === null ? undefined : null
        this.options = {
          ...this.options,
          search: this.search
        }
        if (res._id) {
          this.$store.dispatch('alert/success', this.$t('currentCulture.associate_saved'))
        }
      }).catch((err) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      })
    },
    getColor (status) {
      switch (status) {
        case 'pending':
          return 'gray'
        case 'completed':
          return 'green'
        case 'in_progress':
          return 'blue'
      }
    },
    async getDashboardAccess () {
      await CustomersService.productDashboard('cultura')
        .then(res => {
          this.hasPbiAccess = res.hasAccess
          if (res.error) {
            console.log(res.error)
          }
        })
    },
    getCurrentCultureAssessments (options) {
      return CurrentCultureAssessmentService.list(options, this.user.role)
        .then((res) => ({
          items: res.items,
          total: res.length
        }))
    },
    getRequiredCultures () {
      this.requiredCultures = []
      return RequiredCultureService.listCompleted().then(res => {
        res.forEach(element => {
          this.requiredCultures.push({ text: element.name, value: element._id })
        })
      })
    },
    formatDate (date) {
      if (!date) return null
      let dateAux = date.split('T')[0]
      const [year, month, day] = dateAux.split('-')
      return `${day}/${month}/${year}`
    },
    showLink (tokenUrl) {
      const host = window.location.origin + '/evaluations/actual/'
      this.tokenUrl = host + tokenUrl
      this.showLinkModal = true
    },
    copyUrl () {
      let input = document.getElementById('tokenUrl')
      input.select()
      document.execCommand('copy')
      this.$store.dispatch('alert/success', this.$t('requiredCulture.link_copied'))
    },
    closeModal () {
      this.showModal = false
    },
    notEditable (status) {
      return status === 'completed' || status === 'cancelled'
    },
    filterItems (value) {
      this.options = {
        ...this.options,
        filter: value
      }
    },
    closePoll () {
      return CurrentCultureAssessmentService.close(this.itemToClose)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('currentCulture.closed_poll'))
          this.filterItems()
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    reloadList () {
      this.filterItems()
    }
  },
  created () {
    this.$store.dispatch('session/getSuiteWebHost')
      .then((res) => {
        if (res) {
          const enterpriseToken = this.user.enterprise && this.user.enterprise.invitationCode ? this.user.enterprise.invitationCode : ''
          this.tokenUrl = `${res}/auth/verify-polls/${enterpriseToken}`
          this.getDashboardAccess()
        } else {
          next('/404')
        }
      })
  }
}
</script>

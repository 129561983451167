
<template>
  <v-app>
    <x-navigation ref="navigation"></x-navigation>

    <v-app-bar
      color="#3899da"
      dark
      style="border-bottom:1px solid rgba(0,0,0,.12) !important;"
      app
      fixed
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="toggleNavigation" text></v-app-bar-nav-icon>

      <img src="/img/20200302_occ_cultura_logo.svg"
        style="height: 87%;" class="pl-2"
        alt="OCC - Solutions logo"
      />

      <v-spacer></v-spacer>
      <v-select dense hide-details
        v-model="language"
        :items="languages"
        :menu-props="{ top: false, offsetY: true }"
        class="styled-select my-2 mr-3"
        prepend-icon="translate"
        @change="changeLocale"
      ></v-select>
      <div class="text-xs-center">
        <v-menu
          v-model="openMenu"
          :nudge-width="296"
          :nudge-bottom="40"
          :nudge-left="295"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn text
              v-on="on"
              class="mr-1"
            >
              <v-icon large>mdi-apps</v-icon>
            </v-btn>
          </template>

          <v-card style="max-width: 22.7em">
            <v-row no-gutters v-if="loadingProducts">
              <v-col cols="12" class="py-12 text-center">
                <v-progress-circular indeterminate
                  :width="4"
                  :size="54"
                  color="primary"
                  class="mx-auto"
                ></v-progress-circular>
                <p class="mt-1 mb-0 pl-3 caption grey--text text--darken-2">
                  {{ $t('waiting') }}...
                </p>
              </v-col>
            </v-row>
            <v-list v-else style="max-height: 77vh" class="py-0 overflow-y-auto">
              <v-list-item>
                <v-list-item-content class="pb-0">
                  <v-row>
                    <template v-for="item in products">
                      <v-col md="6" :key="item.name" style="cursor: pointer">
                        <a :href="item.url">
                        <img :src="item.logo || item.defaultLogo"
                            alt="System Logo"
                            :style="item.name === 'occ'
                              ? 'max-height: 50px; max-width: 150px; margin-left: 1em;margin-top: 1.5em;'
                              : item.name.includes('LIDERAZGO')
                                ? 'margin-top: 0.5em; max-width: 160px'
                                : 'max-width: 150px'
                            "
                          />
                        </a>
                      </v-col>
                    </template>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-tooltip left v-if="['customer', 'enterprise_admin'].includes(user.role)">
          <template v-slot:activator="{ on }">
            <v-btn href="https://occ-solutions.com/ayuda/" class="elevation-0" text fab  target="_blank" v-on="on">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('manual') }}</span>
        </v-tooltip>
        <v-menu offset-y v-if="notifications && notifications.unread_number !== undefined">
          <template v-slot:activator="{ on }">
            <v-badge right color="red">
              <template v-slot:badge v-if="notifications.unread_number > 0">
                <span v-if="notifications.unread_number < 100">{{notifications.unread_number}}</span>
                <span v-else>+99</span>
              </template>
            </v-badge>
            <v-btn class="elevation-0" text fab v-on="on">
              <v-icon>notifications</v-icon>
            </v-btn>
          </template>
          <v-list style="max-width: 28em">
            <v-list-item
              v-for="(notification, index) in notifications.last_notifications"
              :key="index"
              :class="!notification.read ? 'unread' : ''"
            >
              <template v-if="user.role === 'admin'">
                <v-list-item-title @click="readNotification(notification)" v-if="notification.sourceType === 'enterprise_plan'">
                  <router-link
                    :to="notification.action"
                    class="no-decoration"
                    :class="!notification.read ? 'font-unread' : 'read'"
                  >
                    {{ $t(`notifications.${notification.type}`, {enterprise: notification.source.name}) }}
                  </router-link>
                </v-list-item-title>
                <v-list-item-title @click="readNotification(notification)" v-else>
                  <router-link
                    :to="notification.action"
                    class="no-decoration"
                    :class="!notification.read ? 'font-unread' : 'read'"
                  >
                    {{ $t(`notifications.${notification.type}`, {enterprise: notification.source.enterprise.name, poll: notification.source.name}) }}
                  </router-link>
                </v-list-item-title>
              </template>
              <template v-else>
                <v-list-item-title @click="readNotification(notification)">
                  <router-link
                    :to="notification.action"
                    class="no-decoration"
                    :class="!notification.read ? 'font-unread' : 'read'"
                  >
                    {{ $t(`notifications.${notification.type}`) }}
                  </router-link>
                </v-list-item-title>
              </template>
            </v-list-item>
            <v-list-item class="see-more-btn" @click="$router.push('/notifications')">
              <v-list-item-title class="seemore">
                <router-link
                  to="/notifications"
                  class="no-decoration"
                >
                  {{$t('notifications.see_more')}}
                </router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="elevation-0" fab color="white">
            <span class="headline primary--text">{{ user.email | initials }}</span>
          </v-btn>
        </template>
        <v-card>
          <v-divider></v-divider>
          <v-list dense>
            <template v-if="user.role !== 'employee'">
              <v-list-item>
                <v-row>
                  <v-col md="3">
                    <div style="background-color: #3899da; width: 52px; height: 52px; padding-left: 2px; padding-top: 2px; border-radius: 50%">
                      <v-avatar color="white" size="48">
                        <span class="headline">{{ user.email | initials }}</span>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col md="9">
                    <p>
                      <span class="text-capitalize">
                        {{
                          user.role === 'customer'
                            ? user.customer.name
                            : user.role === 'enterprise_admin'
                              ? 'Co-Admin.'
                              : 'Super Admin.'
                        }}
                      </span><br>
                      <span style="font-size: .9em">{{user.email}}</span>
                    </p>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item class="text-center" @click="goProfile" v-if="user.role === 'customer' && user.customer.type === 'commercial'">
                <v-list-item-title>
                  <v-icon small>fa-eye</v-icon>
                  {{ $t('input.view_profile') }}
                </v-list-item-title>
              </v-list-item>
              <template v-if="user.role !== 'enterprise_admin'" @click="secondMenu = !secondMenu">
                <change-enterprise-menu
                  :menu="secondMenu"
                  :enterprises="enterprises"
                  :user="user"
                  @search-enterprises="searchEnterprsises"
                />
                <v-divider></v-divider>
              </template>
            </template>
            <v-list-item @click="confirmLogout = true" class="text-center">
              <v-list-item-title style="color: #3899dac7">
                <v-icon color="#3899dac7" small>mdi-logout-variant</v-icon>
                {{ $t('input.logout') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container fluid fill-height :class="{'pa-0': $vuetify.breakpoint.xs}">
        <v-layout :class="{'pa-0': $vuetify.breakpoint.xs}" justify-center>
          <!-- <x-terms-not-accepted
            @acceptNewTerms="acceptNewTerms"
            :user="user"
            v-if="!this.user.hasLastTerms"
          /> -->
          <router-view />
        </v-layout>
      </v-container>
    </v-main>

    <v-footer style="border-top:1px solid rgba(0,0,0,.12) !important;" color="white" app inset>
      <span class="black--text">&nbsp;&nbsp;{{ $t('copyright') }}.</span>
    </v-footer>

    <x-alert></x-alert>
    <x-help-modal></x-help-modal>
    <v-dialog v-model="showSnackbarDialog" width="40em">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('auth.close_session') }}
        </v-card-title>
        <v-card-text>{{ $t(snackMsg) }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="flex-wrap: wrap">
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <template v-if="doubleOrigin">
            <v-btn color="#eb604c" text :href="`${this.suiteWeb}/wp/cfs/customer`">
              <strong>{{ $t('auth.close_session_as_customer') }} </strong>
            </v-btn>
            <v-btn class="ml-0" color="#eb604c" text :href="`${this.suiteWeb}/wp/cfs/enterprise`">
              <strong>{{ $t('auth.close_session_as_enterprise') }} </strong>
            </v-btn>
          </template>
          <v-btn v-else color="#eb604c" text :href="`${this.suiteWeb}/wp/cfs`">
            <strong>{{ $t('auth.close_session') }} </strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-if="showSnackbar"
      color="#424242"
      dark fixed bottom right fab
      @click="showSnackbarDialog = true"
      class="mb-10 mr-3"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
    <x-loading></x-loading>
    <!-- Logout Confirmation -->
    <x-confirm-logout
      :show.sync="confirmLogout"
      @close="confirmLogout = false"
    ></x-confirm-logout>
  </v-app>
</template>

<script>

import { mapState } from 'vuex'

import NotificationService from '../services/notifications'
import ProductsService from '../services/products'
import DashboardService from '../services/dashboard'

// import enterpriseService from '../services/enterprises'
import termsAndConditionsService from '../services/terms-and-conditions'

import ChangeEnterpriseMenu from './customers/change-enterprise-menu.vue'

import { localize, localeChanged } from 'vee-validate'

function loadLocale (code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    locale.messages.required = code === 'es' ? 'Este campo es obligatorio' : 'This field is required'
    localize(code, locale)
  })
}

export default {
  components: {
    ChangeEnterpriseMenu
  },
  data () {
    return {
      openMenu: false,
      confirmLogout: false,
      menu: false,
      languages: [],
      language: 'es',
      notifications: {
        last_notifications: []
      },
      showSnackbar: false,
      showSnackbarDialog: false,
      doubleOrigin: false,
      snackMsg: '',
      host: null,
      suiteWeb: '',
      loadingProducts: true,
      products: [],
      options: {
        filter: null,
        search: null
      },
      enterprises: null,
      secondMenu: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  watch: {
    '$i18n.locale': {
      handler () {
        const languages = this.$t('languages')
        this.languages = []
        for (const key in languages) {
          this.languages.push({ value: key, text: languages[key] })
        }
        this.user.lang = this.language
        const localUsr = JSON.parse(localStorage.getItem('occ-key-user'))
        localUsr.lang = this.user.lang
        localStorage.setItem('occ-key-user', JSON.stringify(localUsr))
      }
    },
    openMenu (val) {
      if (val && !this.products.length) {
        this.loadProducts()
      }
    }
  },
  methods: {
    goProfile () {
      this.$router.push('/commercial-profile')
      this.menu = !this.menu
    },
    toggleNavigation () {
      this.$refs.navigation.toggle()
    },
    changeLocale () {
      this.$i18n.locale = this.language
      loadLocale(this.$i18n.locale)
    },
    fetchEnterprises () {
      /* return enterpriseService.list(this.options)
        .then((res) => {
          this.enterprises = res.items
          this.enterprises.slice(0, 5)
        }) */
    },
    searchEnterprsises (tag) {
      this.options.search = tag
      /* return enterpriseService.list(this.options).then((res) => {
        this.secondMenu = true
        this.enterprises = res.items
        this.enterprises.slice(0, 5)
      }) */
    },
    async readNotification (notification) {
      await NotificationService.readNotification(notification.id).then(
        response => {
          this.getNotifications()
        }
      )
    },
    getNotifications () {
      return NotificationService.list()
        .then((res) => {
          this.notifications = res
        })
        .catch(err => { this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`)) })
    },
    closeMenu () {
      this.menu = !this.menu
    },
    acceptNewTerms (customerId, termsId) {
      return termsAndConditionsService.acceptByCustomer({ customerId, termsId })
        .then((res) => {
          this.user.hasLastTerms = !this.user.hasLastTerms
          this.$store.dispatch('session/updateEnterprise', this.user)
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    getProducts () {
      if (this.user.role === 'admin' && this.user.role === this.user.view) {
        return ProductsService.listActive()
          .then(res => { this.products = res })
      } else if (['customer', 'enterprise_admin'].includes(this.user.role) || ['customer', 'enterprise_admin'].includes(this.user.view)) {
        return ProductsService.listByCustomer()
          .then(res => { this.products = res })
      } else if (this.user.role === 'employee') {
        return DashboardService.getSuiteInfo()
          .then(res => { this.products = res.products })
      }
      return Promise.resolve()
    },
    loadProducts () {
      this.getProducts()
        .then(() => {
          this.products.unshift({
            name: 'OCC SUITE',
            logo: `${this.suiteWeb}/img/20200301_occ_solution_logo.png`,
            url: this.suiteWeb
          })
          if (this.host && this.host.productName) {
            const found = this.products.find(prod => {
              const prodName = prod.name.toLowerCase()
              return prodName.includes(this.host.productName.toLowerCase())
            })
            if (found) {
              this.products.splice(this.products.indexOf(found), 1)
            }
          }
          let imageLoaded = 0
          for (const prod of this.products) {
            const img = new Image()
            img.src = prod.logo || prod.defaultLogo
            img.onload = () => {
              imageLoaded++
              if (imageLoaded === this.products.length) {
                setTimeout(() => {
                  this.loadingProducts = false
                }, 400)
              }
            }
          }
        })
    }
  },
  created () {
    const languages = this.$t('languages')
    for (let key in languages) {
      this.languages.push({ value: key, text: languages[key] })
    }
    this.language = this.user.lang
    this._i18n.locale = this.language
    // this.getNotifications()
    if (this.user.role === 'customer' && this.user.customer.type === 'commercial') {
      this.fetchEnterprises()
    }
    if (this.user.origin) {
      this.showSnackbar = true
      this.doubleOrigin = !!this.user.origin.origin
      if (this.user.role === 'customer' && this.user.customer.type === 'commercial' && this.user.enterprise) {
        this.snackMsg = this.doubleOrigin ? 'auth.logged_as_enterprise_superadmin' : 'auth.logged_as_enterprise'
      } else {
        this.snackMsg = 'auth.logged_as_customer'
      }
    }

    this.$store.dispatch('session/getCultureHost')
      .then(res => {
        this.host = res
        return this.$store.dispatch('session/getSuiteWebHost')
      })
      .then(res => {
        if (res) {
          this.$set(this, 'suiteWeb', res)
        }
      })
  },
  mounted () {
    const urlString = window.location.href
    const url = new URL(urlString)
    const updatedAt = url.searchParams.get('updatedAt')
    if (updatedAt) {
      console.log('Updated at 2024-12-09 14:00')
    }
  }
}
</script>

<style scoped>

.styled-select,
.styled-select label[for] {
  max-width: 140px;
  font-size: 10pt;
}

.unread {
  background-color: #3899da47;
}

.unread:hover {
  background-color: white;
}

.seemore {
  text-align: center;
}

.no-decoration {
  text-decoration: none;
}

.font-unread {
  color: midnightblue;
}

.see-more-btn {
  background-color: #e0e0e087;
}

.see-more-btn:hover {
  background-color: aliceblue;
}

.read {
  cursor: auto;
}
</style>
